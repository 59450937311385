.App {
  min-height: 100vh;
  background: white;
}

@media (min-width: 960px) {
  .App {
    display: grid;
    align-content: center;
    grid-template-columns: 7vw 3fr 1fr;
    grid-template-rows: 68px 1fr 12vh;
    background: url("../../assets/bg-grad.svg") no-repeat -25vw 40vh;
  }
}
